import "./index.css";

const Nav = () => {
  return (
    <div className='nav-container'>
      <div className='logo'>
        <svg
          t='1682261157564'
          className='icon'
          viewBox='0 0 1024 1024'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          p-id='10300'
          data-spm-anchor-id='a313x.7781069.0.i9'
          width='40'
          height='40'
        >
          <path
            d='M512 103.26016c-226.2016 0-409.6 183.3984-409.6 409.6 0 214.67136 165.2736 390.49216 375.47008 407.87968V720.36352a271.03232 271.03232 0 0 0-156.53888 77.24032l-48.26112-48.26112a341.53472 341.53472 0 0 1 59.06432-47.0016C257.52576 671.744 204.8 598.36416 204.8 512.86016h68.27008c0 75.39712 61.1328 136.52992 136.52992 136.52992h68.27008v-102.4c-112.93696 0-204.8-91.86304-204.8-204.8h68.25984c0 75.3664 61.14304 136.54016 136.54016 136.54016v-65.87392c0-72.92928-28.39552-141.52704-79.94368-193.06496l48.27136-48.26112c38.47168 38.46144 65.49504 83.99872 81.72544 132.33152 14.20288-23.26528 30.7712-44.9024 49.94048-64.07168l48.26112 48.27136a271.5136 271.5136 0 0 0-70.4 121.92768c69.33504-2.39616 134.1952-30.19776 183.47008-79.4624l48.27136 48.26112c-66.57024 66.60096-153.86624 99.90144-241.24416 99.9424 0 0.79872-0.1024 1.55648-0.1024 2.3552v163.46112c58.76736-15.21664 102.4-68.15744 102.4-131.69664H716.8c0 49.43872-17.60256 94.79168-46.82752 130.23232A271.07328 271.07328 0 0 0 805.0688 569.4464l48.26112 48.2816c-66.60096 66.60096-153.92768 99.92192-241.32608 99.92192h-65.87392v203.07968C756.3264 903.36256 921.6 727.54176 921.6 512.86016c0-226.19136-183.3984-409.6-409.6-409.6z'
            p-id='10301'
          ></path>
        </svg>
        <h1>云树（海南经济特区）科技有限公司</h1>
      </div>

      <div className='nav-links'>
        <a href='/'>首页</a>
        <a href='/about'>关于云树</a>
        <a href='/contact'>联系我们</a>
      </div>
    </div>
  );
};

export default Nav;
