import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import "./index.css";

const Aboutus = () => {
  return (
    <div className='aboutus'>
      <div className='aboutus-container'>
        <img
          width='600'
          height={"400"}
          src='http://lc-mzK8jKT8.cn-n1.lcfile.com/Kzd7D12H5LBrbAhaTz58FLu2ccDs8vvt/team.jpg'
        ></img>
        <div className='aboutus-text'>
          <h2>公司简介</h2>
          <p>
            <strong>公司概述：</strong>
            云树（海南经济特区）科技有限公司，成立于2021年，是一家专注于技术创新和数字化解决方案的高科技企业。我们的创始团队由来自百度、阿里等国内大厂的顶尖人才组成，汇聚了丰富的技术经验和创业精神。我们致力于推动科技在各行各业的应用，为客户提供卓越的技术解决方案，助力他们实现数字化转型和业务增长。
          </p>
          <p>
            <strong>核心价值：</strong>
            我们的核心价值在于创新、合作和客户导向。我们坚信创新是推动社会进步的关键，通过与客户和合作伙伴紧密合作，我们共同探索前沿技术并为其创造实际价值。
          </p>
          <p>
            <h4>核心业务领域：</h4>
            <strong>数字化解决方案：</strong>
            我们为企业提供定制化的数字化解决方案，涵盖人工智能、大数据分析、云计算、物联网等领域，帮助企业实现高效运营和创新增长。
            <br />
            <strong>软件开发：</strong>
            我们拥有丰富的软件开发经验，能够为客户开发高质量的应用程序和系统，满足不同行业的需求。
            <br />
            <strong>技术咨询和培训：</strong>
            我们提供技术咨询服务，帮助客户制定科技发展战略并解决技术难题。此外，我们还开展培训课程，帮助客户培养内部技术团队。
          </p>
          <p>
            <strong>使命和愿景：</strong>
            我们的使命是通过技术创新和卓越的服务，为客户创造价值，推动社会发展。我们的愿景是成为业界领先的技术创新公司，为全球客户提供可靠的数字化解决方案。
          </p>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <div className='about-container'>
      <Nav />
      <Aboutus />
      <Footer />
    </div>
  );
};

export default About;
