import Nav from "../../components/Nav";
import Banner from "../../components/Banner";
import Starups from "../../components/Starups";
import Footer from "../../components/Footer";

import "./index.css";

const OurGoal = () => {
  return (
    <div className='our-goal-container'>
      <div className='our-goal'>
        <h3>我们的目标</h3>
        <p>
          当今最领先的响应式自助建站平台，我们的流线式网页布局设计方案和可视化图文内容编辑模式让网站制作和维护成为一件轻松惬意的事。我们的流线式网页布局设计方案和可视化图文内容编辑模式让网站制作和维护成为起飞页提供了海量精美网站模板和成品网站。
        </p>
      </div>
    </div>
  );
};

const GoodPoints = () => {
  return (
    <div className='good-points'>
      <div>
        <svg
          t='1682257343511'
          class='icon'
          viewBox='0 0 1024 1024'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          width='38'
          height='38'
        >
          <path
            d='M756.429781 519.677903 504.174087 519.677903 504.174087 268.101847 440.742151 268.101847 440.941512 582.704069 756.429781 582.704069Z'
            p-id='2630'
          ></path>
          <path
            d='M504.409695 16.445242c-278.164527 0-503.661589 225.497061-503.661589 503.662596s225.497061 503.662596 503.661589 503.662596c278.166541 0 503.664609-225.497061 503.664609-503.662596S782.575229 16.445242 504.409695 16.445242zM504.415736 897.875173c-208.635981 0-377.768343-169.132361-377.768343-377.767336s169.133368-377.768343 377.768343-377.768343 377.768343 169.133368 377.768343 377.768343S713.051717 897.87618 504.415736 897.875173z'
            p-id='2631'
          ></path>
        </svg>
        <h3>省时间</h3>
        <p>官网平台能够很快速的完成搭建</p>
      </div>
      <div>
        <svg
          t='1682257361142'
          class='icon'
          viewBox='0 0 1024 1024'
          version='1.1'
          width='38'
          height='38'
        >
          <path
            d='M827.733333 512l157.866667-157.866667c42.666667-42.666667 42.666667-110.933333 0-149.333333L819.2 38.4c-38.4-38.4-110.933333-38.4-149.333333 0L512 196.266667 354.133333 38.4C315.733333 0 243.2 0 204.8 38.4L38.4 204.8c-42.666667 42.666667-42.666667 110.933333 0 149.333333L196.266667 512 128 576c-12.8 12.8-21.333333 25.6-25.6 42.666667L8.533333 878.933333c-12.8 34.133333-4.266667 68.266667 12.8 93.866667 21.333333 25.6 51.2 42.666667 85.333334 42.666667 12.8 0 21.333333 0 34.133333-4.266667l260.266667-93.866667c17.066667-4.266667 29.866667-12.8 42.666666-25.6l64-64 157.866667 157.866667c21.333333 21.333333 46.933333 29.866667 76.8 29.866667s55.466667-12.8 76.8-29.866667l166.4-166.4c42.666667-42.666667 42.666667-110.933333 0-149.333333L827.733333 512z m-81.066666-422.4c4.266667 0 12.8 4.266667 17.066666 4.266667l166.4 166.4c8.533333 8.533333 8.533333 21.333333 0 29.866666l-59.733333 59.733334-196.266667-196.266667 59.733334-59.733333s4.266667-4.266667 12.8-4.266667zM200.533333 396.8l76.8-76.8c12.8-12.8 12.8-34.133333 0-46.933333s-34.133333-12.8-46.933333 0L153.6 349.866667 98.133333 294.4c-8.533333-8.533333-8.533333-21.333333 0-29.866667l166.4-166.4c4.266667-4.266667 8.533333-4.266667 17.066667-4.266666s12.8 4.266667 17.066667 4.266666L452.266667 256 256 452.266667 200.533333 396.8z m187.733334 439.466667s-4.266667 4.266667-8.533334 4.266666l-260.266666 93.866667c-8.533333 4.266667-17.066667 0-21.333334-8.533333-4.266667-4.266667-4.266667-8.533333-4.266666-17.066667l93.866666-260.266667c0-4.266667 4.266667-4.266667 4.266667-8.533333l93.866667-93.866667 256-256 85.333333-85.333333 196.266667 196.266667-85.333334 85.333333-256 256-93.866666 93.866667z m537.6-76.8l-166.4 166.4c-8.533333 8.533333-21.333333 8.533333-29.866667 0l-55.466667-55.466667 76.8-76.8c12.8-12.8 12.8-34.133333 0-46.933333s-34.133333-12.8-46.933333 0l-76.8 76.8-55.466667-55.466667 196.266667-196.266667 157.866667 157.866667c8.533333 8.533333 8.533333 21.333333 0 29.866667z'
            p-id='3735'
          ></path>
        </svg>
        <h3>定制化</h3>
        <p>可以针对您的特有场景进行定制化服务</p>
      </div>
      <div>
        <svg
          t='1682257375461'
          class='icon'
          viewBox='0 0 1024 1024'
          version='1.1'
          width='38'
          height='38'
        >
          <path
            d='M792.125979 126.920668c0-34.859949-31.343869-63.12162-70.028936-63.12162L301.913191 63.799048c-38.685068 0-70.039169 28.261671-70.039169 63.12162L231.874021 897.084449c0 34.859949 31.354102 63.116503 70.039169 63.116503l420.183851 0c38.685068 0 70.028936-28.256554 70.028936-63.116503L792.125979 126.920668 792.125979 126.920668zM302.639738 158.483524l419.456281 0 0 643.925658L301.902958 802.409183 302.639738 158.483524 302.639738 158.483524zM406.961712 95.361905l212.053604 0 0 31.558763L406.961712 126.920668 406.961712 95.361905 406.961712 95.361905zM511.267313 849.744257c19.34765 0 35.024701 14.132882 35.024701 31.562856 0 17.424858-15.677051 31.558763-35.024701 31.558763-19.332301 0-35.014468-14.132882-35.014468-31.558763C476.252845 863.878163 491.935012 849.744257 511.267313 849.744257L511.267313 849.744257zM511.267313 849.744257'
            p-id='4743'
          ></path>
        </svg>
        <h3>移动端</h3>
        <p>支持移动端</p>
      </div>
    </div>
  );
};

const OurAdvantage = () => {
  return (
    <div>
      <div className='our-advantage'>
        <img
          width='526'
          height='516'
          src='http://lc-mzK8jKT8.cn-n1.lcfile.com/rzi4TSPs6PMz5cc4CFIzhYCLWJ1BeQc4/pc.png'
          className='our-advantage-img-pc'
          alt='Phone'
          data-title='Phone'
        ></img>
        <div className='our-advantage-content'>
          {/* <h3>软件平台核心优势</h3> */}
          <div>
            <div>
              <h4>集成性和一体化</h4>
              <p>
                软件平台通常可以集成多个不同的功能、工具和服务，使其在同一环境中协同工作。这种一体化的特性可以提高工作效率，减少数据转移和集成的难度，从而加快开发和部署的速度。
              </p>
              <h4>开发和定制能力</h4>
              <p>
                软件平台提供了一种构建和定制应用程序的基础，使开发人员能够利用平台上的工具、库和组件来创建新的功能和应用。这有助于降低开发成本和时间，并且可以适应不同的业务需求。
              </p>
              <h4>生态系统和社区支持</h4>
              <p>
                强大的软件平台通常拥有庞大的开发者和用户社区，这意味着可以共享经验、资源和最佳实践。社区支持有助于解决问题、提供新的创意和解决方案，从而增强了平台的功能和可持续性。{" "}
              </p>
              <h4>扩展性和可伸缩性</h4>
              <p>
                好的软件平台设计可以轻松地扩展以适应不断增长的需求，而不会牺牲性能或稳定性。这对于应对用户增长或数据量增加等挑战非常重要。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='our-advantage why-us'>
        <div className='our-advantage-content'>
          {/* <h3>软件平台核心优势</h3> */}
          <div>
            <div>
              <h4>安全性和合规性</h4>
              <p>
                软件平台可以在一个集中的地方管理安全性措施，确保用户数据和机密信息的保护。此外，合规性要求（如GDPR、HIPAA等）可以在整个平台上得到更好的管理和实施。
              </p>
              <h4>更新和维护简化</h4>
              <p>
                软件平台可以使更新、升级和维护变得更加简化，因为更改可以集中在平台级别上，然后应用到所有相关应用程序中，减少了分散的管理工作。
              </p>
              <h4>跨平台和多设备支持</h4>
              <p>
                一些软件平台具备跨平台和多设备支持，使应用程序能够在不同的操作系统和设备上运行，从而扩大了用户范围。
              </p>
              <h4>数据驱动决策</h4>
              <p>
                借助分析和报告工具，软件平台可以帮助用户基于数据做出更明智的决策。这可以通过可视化、报表和分析功能来实现。
              </p>
              <h4>成本效益</h4>
              <p>
                软件平台通常提供了共享资源和基础设施，这可以降低硬件、软件和人力资源方面的成本。
              </p>
            </div>
          </div>
        </div>
        <img
          width='526'
          height='516'
          src='http://lc-mzK8jKT8.cn-n1.lcfile.com/uwPNSd5Hfdcr4Ayn6AUfq3PnmhfWvrml/phone.png'
          className='our-advantage-img-mobile'
          alt='Phone'
          data-title='Phone'
        ></img>
      </div>
    </div>
  );
};

const OurTeam = () => {
  return (
    <div className='our-team-container'>
      <h3>我们的团队</h3>
      <div className='team-member'>
        <div className='our-team'>
          <div className='picture'>
            <img
              className='img-fluid'
              src='https://picsum.photos/130/130?image=1027'
            />
          </div>
          <div className='team-content'>
            <h3 className='name'>Michele Miller</h3>
            <h4 className='title'>Web Developer</h4>
          </div>
        </div>
        <div className='our-team'>
          <div className='picture'>
            <img
              className='img-fluid'
              src='https://picsum.photos/130/130?image=1027'
            />
          </div>
          <div className='team-content'>
            <h3 className='name'>Michele Miller</h3>
            <h4 className='title'>Web Developer</h4>
          </div>
        </div>
        <div className='our-team'>
          <div className='picture'>
            <img
              className='img-fluid'
              src='https://picsum.photos/130/130?image=1027'
            />
          </div>
          <div className='team-content'>
            <h3 className='name'>Michele Miller</h3>
            <h4 className='title'>Web Developer</h4>
          </div>
        </div>
        <div className='our-team'>
          <div className='picture'>
            <img
              className='img-fluid'
              src='https://picsum.photos/130/130?image=1027'
            />
          </div>
          <div className='team-content'>
            <h3 className='name'>Michele Miller</h3>
            <h4 className='title'>Web Developer</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

function Home() {
  return (
    <div className='home'>
      <Nav />
      <Banner />
      <OurGoal />
      <GoodPoints />
      <OurAdvantage />
      {/* <OurTeam /> */}
      {/* <Starups /> */}
      <Footer />
    </div>
  );
}

export default Home;
