import "./index.css";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-logo'>
        <h3>云树（海南经济特区）科技有限公司</h3>
        <div>
          <p>Copyright © 2023 Yun Shu Tech ltd. </p>
          <p>
            <a
              className='beian'
              href='https://beian.miit.gov.cn/'
              target='_blank'
            >
              琼ICP备2023002486号
            </a>
          </p>
        </div>
      </div>
      <div className='footer-contact'>
        <h3>联系我们</h3>
        <div>
          <p>电话：+86 150-1089-0835</p>
          <p>邮箱：pearyman@gmail.com</p>
          <p>地址：三亚市天涯区文明路289号</p>
        </div>
      </div>
      <div className='footer-helpcenter'>
        <h3>帮助中心</h3>
        <div>
          <p>关于我们</p>
          <p>服务支持</p>
          <p>法律和隐私</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
