import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home/index.js";
import Contact from "../pages/contact/index.js";
import About from "../pages/about/index.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

export default router;
