import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import "./index.css";

const Contactus = () => {
  return (
    <div className='contactus-container'>
      <div className='contactus-msg'>
        <h2>联系信息</h2>
        <div className='contactus-msg-detail'>
          <div>
            <svg
              t='1682259581522'
              className='icon'
              viewBox='0 0 1024 1024'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              p-id='5739'
              width='40'
              height='40'
            >
              <path
                d='M511.998977 65.290005c-173.638689 0-314.904063 138.294716-314.904063 308.281225 0 77.603449 31.020504 185.005574 85.10633 294.67023 53.746088 108.971877 124.852566 209.287607 195.185424 275.377838 8.955976 9.602705 21.51092 15.08865 34.612309 15.08865 12.913101 0 25.359574-5.358031 34.296107-14.736633 149.549038-140.014894 280.608979-406.358985 280.608979-570.401108C826.904063 203.584722 685.637666 65.290005 511.998977 65.290005zM517.467525 914.127613l-1.128707 1.13894c-0.816598 0.8913-2.232854 1.952468-4.339842 1.952468-2.245134 0-3.695159-1.251503-4.361331-1.997494l-1.294482-1.327228C366.207519 782.579555 238.584863 525.041014 238.584863 373.572254c0-147.109476 122.652458-266.791276 273.414113-266.791276 150.761656 0 273.415137 119.6818 273.415137 266.791276C785.414113 525.483082 657.700383 783.130094 517.467525 914.127613z'
                fill='#272636'
                p-id='5740'
              ></path>
              <path
                d='M513.044796 181.616384c-91.091648 0-165.199483 74.112951-165.199483 165.210739 0 91.076298 74.107835 165.172877 165.199483 165.172877 91.083461 0 165.184133-74.096579 165.184133-165.172877C678.228929 255.729336 604.128257 181.616384 513.044796 181.616384zM513.044796 470.51005c-68.213591 0-123.709533-55.484685-123.709533-123.682927 0-68.219731 55.495942-123.720789 123.709533-123.720789 68.205405 0 123.694183 55.501058 123.694183 123.720789C636.738979 415.025365 581.2502 470.51005 513.044796 470.51005z'
                fill='#272636'
                p-id='5741'
              ></path>
            </svg>
            <p>海南省三亚市天涯区文明路289号</p>
          </div>
          <div>
            <svg
              t='1682259670496'
              className='icon'
              viewBox='0 0 1024 1024'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              p-id='6736'
              width='40'
              height='40'
            >
              <path
                d='M0.5 240v544c0 35.3 28.7 64 64 64h896c35.3 0 64-28.7 64-64V240c0-35.3-28.7-64-64-64h-896c-35.3 0-64 28.7-64 64z m72 0h880c4.4 0 8 3.6 8 8v44L570.8 493.6c-36.9 19.1-80.7 19.1-117.6 0l-388.7-201V248c0-4.4 3.6-8 8-8z m880 544h-880c-4.4 0-8-3.6-8-8V364.6l388.7 201.1c36.9 19.1 80.7 19.1 117.6 0L960.5 364v412c0 4.4-3.6 8-8 8z'
                p-id='6737'
              ></path>
            </svg>
            <p> 电话：+86 150-1089-0835</p>
            <p> 邮箱：pearyman@gmail.com</p>
          </div>
          <div>
            <svg
              t='1682259720859'
              className='icon'
              viewBox='0 0 1024 1024'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              p-id='7754'
              width='40'
              height='40'
            >
              <path
                d='M511.333 127.333c51.868 0 102.15 10.144 149.451 30.15 45.719 19.337 86.792 47.034 122.078 82.321 35.287 35.286 62.983 76.359 82.321 122.078 20.006 47.3 30.15 97.583 30.15 149.451s-10.144 102.15-30.15 149.451c-19.337 45.719-47.034 86.792-82.321 122.078-35.286 35.287-76.359 62.983-122.078 82.321-47.3 20.006-97.583 30.15-149.451 30.15s-102.15-10.144-149.451-30.15c-45.719-19.337-86.792-47.034-122.078-82.321-35.287-35.286-62.983-76.359-82.321-122.078-20.006-47.3-30.15-97.583-30.15-149.451s10.144-102.15 30.15-149.451c19.337-45.719 47.034-86.792 82.321-122.078 35.286-35.287 76.359-62.983 122.078-82.321 47.301-20.006 97.583-30.15 149.451-30.15m0-64c-247.424 0-448 200.576-448 448s200.576 448 448 448 448-200.576 448-448-200.576-448-448-448z'
                fill=''
                p-id='7755'
              ></path>
              <path
                d='M702.856 543.333H511.333c-17.673 0-32-14.326-32-32v-286.73c0-17.673 14.327-32 32-32s32 14.327 32 32v254.73h159.522c17.673 0 32 14.327 32 32s-14.326 32-31.999 32z'
                fill=''
                p-id='7756'
              ></path>
            </svg>
            <p>Mon-Fri: 8:00 - 16:00</p>
            <p>Sat-Sun: Closed</p>
          </div>
        </div>
      </div>
      <div className='contact-us'>
        <div className='container'>
          <div className='row'>
            <h2 className='contact-us-title'>随时联系我们</h2>
          </div>
          <div className='row input-container'>
            <div className='col-xs-12'>
              <div className='styled-input wide'>
                <input type='text' required />
                <label>姓名</label>
              </div>
            </div>
            <div className='col-md-6 col-sm-12'>
              <div className='styled-input wide'>
                <input type='text' required />
                <label>电话号码</label>
              </div>
            </div>
            <div className='col-xs-12'>
              <div className='styled-input wide'>
                <textarea required></textarea>
                <label>内容</label>
              </div>
            </div>
            <div className='col-xs-12 btn-container'>
              <a href='###' className='btn-lrg submit-btn'>
                提交
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Contact = () => {
  return (
    <div className='contact'>
      <Nav />
      <Contactus />
      <Footer />
    </div>
  );
};

export default Contact;
