import "./index.css";

const Banner = () => {
  return (
    <div className='banner-container'>
      <div className='overlay'>
        <div className='banner-content'>
          <div className='banner-text'>
            <h1>企业数字化平台</h1>
            <p>
              打造企业级云原生PaaS+SaaS平台，帮助客户轻松用云、快速创新、持续迭代。
            </p>
            <a href='javascript:void(0);'>了解更多</a>
          </div>
          <img
            src='http://lc-mzk8jkt8.cn-n1.lcfile.com/OKv8uorsySnmPV3okwhIcKwLze9WqLLT/content.png'
            alt=''
            width='547'
            height='486'
            className='banner-img'
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
